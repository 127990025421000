import axios from "axios";
import { datadogRum } from "@datadog/browser-rum";

const initializeApp = () => {
  // Setting base URL for all API request via axios
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    // dev code
  } else {
    // Prod build code

    // Removing console.log from prod
    console.log = () => {};

    // init analytics here

    datadogRum.init({
      applicationId: "3b900f38-e886-45e6-9ed8-77723242c500",
      clientToken: "pub57ec90dcceef682a8bf7e4f58b243343",
      site: "datadoghq.eu",
      service: "yatzybot",
      env: "prod",
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
    });

    datadogRum.startSessionReplayRecording();
  }
};

export default initializeApp;
