import React, { lazy, useEffect, useState, Suspense } from "react";
import { ReactQueryDevtools } from "react-query/devtools";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { themeChange } from "theme-change";
import { QueryClient, QueryClientProvider } from "react-query";
import checkAuth from "./app/auth";
import initializeApp from "./app/init";
import { AuthProvider } from "./context/authProvider";
import SuspenseContent from "./containers/SuspenseContent";

// Importing pages
const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const Confirm = lazy(() => import("./pages/Confirm"));
const Reset = lazy(() => import("./pages/Reset"));
const Register = lazy(() => import("./pages/Register"));
const Mfa = lazy(() => import("./pages/Mfa"));
const DocLayout = lazy(() => import("./containers/DocLayout"));

const queryClient = new QueryClient();
let mfaenabled2;
let role2;

// Initializing different libraries
initializeApp();

function App() {
  const [user, setUser] = useState({});
  const [load, setLoad] = useState(false);

  useEffect(() => {
    // 👆 daisy UI themes initialization
    themeChange(false);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = await checkAuth();
        setLoad(true);
        setUser(user);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchData();
  }, []);

  return (
    <Suspense fallback={<SuspenseContent />}>
      {load && (
        <QueryClientProvider client={queryClient}>
          <AuthProvider
            auth={{ role: user?.role, mfaenabled: user?.mfaenabled }}
          >
            <Router>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset" element={<Reset />} />
                <Route path="/register" element={<Register />} />
                <Route path="/confirm" element={<Confirm />} />
                <Route path="/mfa" element={<Mfa />} />
                <Route path="/documentation" element={<DocLayout />} />

                {/* Place new routes over this */}
                <Route path="/*" element={<Layout />} />

                <Route
                  path="*"
                  element={<Navigate to={role2 ? "/" : "/login"} replace />}
                />
              </Routes>
            </Router>
          </AuthProvider>
          <ReactQueryDevtools initialIsOpen="true" />
        </QueryClientProvider>
      )}
    </Suspense>
  );
}

export default App;
