import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "company.name": "Yatzybot",
      "home.header": "The way to roll with full control",
      "home.header.1": "Yatzy Bot - Your All-in-One Crypto Trading Solution",
      "home.Tagline":
        "Roll the dice and win big with our powerful crypto trading bot.",
      "home.button.callToAction": "Try Yatzy Bot Now",
      "home.features.1":
        "Our bot uses advanced algorithms to automate your trading strategy, 24/7. Yatzy Bot takes the guesswork out of trading and ensures you never miss an opportunity.",
      "home.features.2":
        "Test your strategy on historical data to see how it would perform in real-world conditions. Yatzy Bot supports backtesting on multiple exchanges and timeframes, so you can fine-tune your strategy for maximum profitability.",
      "home.features.3":
        "Choose from a wide range of technical indicators to customize your trading strategy. Yatzy Bot supports popular indicators such as moving averages, Bollinger Bands, and MACD, as well as more advanced indicators like Ichimoku Cloud and RSI.",
      "home.features.4":
        "Yatzy Bot allows you to manage your portfolio with ease. Track your assets, set price alerts, and get real-time notifications when important events occur in the market.",
    },
  },
  fr: {
    translation: {
      "Welcome to React": "Bienvenue à React et react-i18next",
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
