import React, { createContext, useState } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ auth, children }) => {
  const [currentAuth, setAuth] = useState(auth);

  return (
    <AuthContext.Provider value={{ currentAuth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);
