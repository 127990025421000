import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { datadogRum } from "@datadog/browser-rum";
import { check } from "../api/users";

const checkAuth = async () => {
  try {
    const { user } = await check();

    // window.location.href = "/login";

    const PUBLIC_ROUTES = [
      "login",
      "forgot-password",
      "register",
      "documentation",
      "confirm",
      "reset",
      "mfa",
    ];

    const isPublicPage = PUBLIC_ROUTES.some((r) =>
      window.location.href.includes(r)
    );

    if (!user?.role && !isPublicPage) {
      window.location.href = "/login";
    }
    if (process.env.NODE_ENV !== "development") {
      datadogRum.setUser({
        email: user.email,
      });
    }

    // axios.defaults.headers.common.Authorization = `Bearer ${token}`;

    axios.interceptors.request.use(
      (config) =>
        // UPDATE: Add this code to show global loading indicator
        // document.body.classList.add('loading-indicator');
        config,
      (error) => Promise.reject(error)
    );

    axios.interceptors.response.use(
      (response) => {
        // UPDATE: Add this code to hide global loading indicator
        document.body.classList.remove("loading-indicator");
        return response;
      },
      (error) => {
        document.body.classList.remove("loading-indicator");

        const logout = async () => {
          const { data } = await axios.get(`/users/logout`);
        };

        if (error?.response?.status === 401) {
          logout();
          window.location.href = "/login";
        }

        return Promise.reject(error);
      }
    );
    return { role: user.role, mfaenabled: user.mfaenabled };
  } catch (err) {
    console.log("error");
    return null;
  }
};

export default checkAuth;
