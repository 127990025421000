import axios from "axios";

const userpath = "/users";

export async function fetchUsers() {
  const { data } = await axios.get(`/users`);
  return data;
}

export async function fetchUser() {
  const { data } = await axios.get(`/users/hent`);
  return data;
}

export async function upgrade() {
  const { data } = await axios.get(`/users/upgrade`);
  return data;
}

export async function login({ email, password }) {
  const { data } = await axios.post("/users/login", {
    email,
    password,
  });
  return data;
}

export async function confirm(token) {
  const { data } = await axios.post("/users/confirm", {
    token,
  });
  return data;
}

export async function reset({ token, password }) {
  const { data } = await axios.post("/users/reset", {
    token,
    password,
  });
  return data;
}

export async function register({ username, email, password }) {
  const { data } = await axios.post("/users/create", {
    username,
    email,
    password,
  });
  return data;
}

export async function passwordreset(email) {
  const { data } = await axios.post("/users/passwordreset", {
    email,
  });
  return data;
}

export async function verifyOtp(code) {
  const { data } = await axios.post("/users/verifyotp", {
    code,
  });

  return data;
}

export async function check() {
  const { data } = await axios.get(`/users/check`);
  return data;
}
