import { createSlice } from "@reduxjs/toolkit";

export const rightDrawerSlice = createSlice({
  name: "rightDrawer",
  initialState: {
    header: "", // current  title state management
    isOpen: false, // right drawer state management for opening closing
    bodyType: "", // right drawer content management
    errors: [],
  },
  reducers: {
    openRightDrawer: (state, action) => {
      const { header, bodyType } = action.payload;
      state.isOpen = true;
      state.bodyType = bodyType;
      state.header = header;
    },

    closeRightDrawer: (state, action) => {
      state.isOpen = false;
      state.bodyType = "";
      state.header = "";
    },

    addErrors: (state, action) => {
      const { errors } = action.payload;
      state.errors = errors;
    },
  },
});

export const { openRightDrawer, closeRightDrawer, addErrors } =
  rightDrawerSlice.actions;

export default rightDrawerSlice.reducer;
